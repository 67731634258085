import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { List } from 'wui/List';
import { Fade } from 'wui/Fade';

import { GroupListItemSkeleton } from './GroupListItem';

type GroupListSkeletonProps = {
  length?: number;
  wired?: boolean;
};

export const GroupListSkeleton: React.FC<GroupListSkeletonProps> = ({
  length,
  wired,
}) => {
  const { isMobile } = useEnvironment();

  return (
    <Fade key="loading">
      <List
        disableGutters={isMobile}
        gap={isMobile ? 'SP4' : 'SP5'}
        paddingLeft={isMobile ? 'SP0' : 'SP5'}
        paddingRight={isMobile ? 'SP0' : 'SP5'}
      >
        {getPlaceholders(length as number, wired)}
      </List>
    </Fade>
  );
};

GroupListSkeleton.displayName = 'GroupListSkeleton';
GroupListSkeleton.defaultProps = {
  length: 10,
};

export function getPlaceholders(count: number, wired?: boolean) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupListItemSkeleton wired={wired} />
    </Fade>
  ));
}
