import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsSearchSearchResultSrc111Evid523 } from '@wix/bi-logger-groups-data/v2';

import { Box } from 'wui/Box';
import { List } from 'wui/List';
import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { Divider } from 'wui/Divider';
import { EmptyState } from 'wui/EmptyState';

import {
  FeedItemSearchResult,
  FeedItemSearchResultSkeleton,
} from 'common/components/FeedSearchResults';
import {
  selectFeedResult,
  selectFeedSearchItems,
  selectFeedSearchMetadata,
} from 'store/selectors';

interface IProps {
  query: string;
  container: HTMLElement | null;

  onReset(): void;

  origin: string;
  onLoadMore(): void;
  groupId?: string;
}

export function FeedResultsList(props: IProps) {
  const { origin, groupId, container, query, onReset, onLoadMore } = props;

  const bi = useBi();
  const { t } = useTranslation();

  const result = useSelector(selectFeedResult);
  const items = useSelector(selectFeedSearchItems);
  const meta = useSelector(selectFeedSearchMetadata);

  const hasMore = !!result.nextCursor;

  useEffect(() => {
    if (result.total) {
      bi.report(
        groupsSearchSearchResultSrc111Evid523({
          origin,
          groupId,
          responseStatus: 'success',
          outputJson: JSON.stringify({ posts: result.total }),
          tabName: 'posts',
        }),
      );
    } else {
      bi.report(
        groupsSearchSearchResultSrc111Evid523({
          groupId,
          origin,
          responseStatus: 'no_results',
          tabName: 'posts',
        }),
      );
    }
  }, [result.total]);

  if (!items.length) {
    return (
      <EmptyState
        bw
        variant="section"
        title={t('groups-web.group-search.feed-empty-state.title')}
        subtitle={t('groups-web.group-search.feed-empty-state.subtitle')}
        action={
          <Button outlined variant="basic" onClick={onReset}>
            {t('groups-web.group-search.feed-empty-state.reset-button')}
          </Button>
        }
      />
    );
  }

  return (
    <Stack gap="SP2" direction="vertical">
      <Box padding="SP0 SP6">
        <Typography secondary>
          {t('groups-web.group-search.feed.results-total', {
            count: result.total,
          })}
        </Typography>
      </Box>

      <InfiniteScroll
        threshold={400}
        useWindow={false}
        hasMore={hasMore}
        initialLoad={false}
        loadMore={onLoadMore}
        getScrollParent={() => container}
        loader={
          <Show if={meta.isLoadingMore}>
            <Divider bw inset />
            <FeedItemSearchResultSkeleton />
          </Show>
        }
      >
        <List disablePadding separator={<Divider bw inset />}>
          {items.map((item) => (
            <FeedItemSearchResult
              bw
              item={item}
              key={item.feedItemId}
              topBottomBorders={false}
              sideBorders={false}
              promote={!groupId}
              origin={origin}
              query={query}
            />
          ))}
        </List>
      </InfiniteScroll>
    </Stack>
  );
}

FeedResultsList.displayName = 'FeedResultsList';
