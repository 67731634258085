import React from 'react';
import cls from 'classnames';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import {
  groupsRequestJoinAGroup,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/v2';
import { useRouter } from '@wix/tpa-router/react';

import { EImageRatio } from 'settings/constants';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemButton } from 'wui/ListItemButton';
import { Box } from 'wui/Box';
import type { TPAComponentProps } from 'wui/types';
import { ButtonSize } from 'wui/Button';
import { ListItemAction } from 'wui/ListItemAction';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { GroupImage } from 'common/components/GroupImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group, wired, className, ...rest } = props;

  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const router = useRouter();

  const shouldShowMembershipButton =
    isMobile || !experiments.enabled('specs.groups.UpdateSidebarLayout');

  return (
    <ListItem
      {...rest}
      disablePadding
      actionOnHover={!isMobile}
      className={cls(className, { [classes.wired]: props.wired })}
    >
      <ListItemButton
        onClick={handleGroupClick}
        disablePadding={isMobile}
        data-hook="group-list-item-cta"
        className={cls(classes['item-button'], {
          [classes['suggested-item-button']]:
            experiments.enabled('specs.groups.UpdateSidebarLayout') &&
            !isMobile,
        })}
      >
        <ListItemIcon className={classes.image}>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              className={classes.image}
              image={group.coverImage?.image}
              aspectRatio={EImageRatio.Square}
            />
          </Box>
        </ListItemIcon>

        <ListItemText
          title={group.name}
          subtitle={
            <GroupInfo
              extended={false}
              wired={wired}
              groupId={group.id as string}
            />
          }
          subtitleProps={{ noWrap: true }}
          titleProps={{
            className: cls(classes.name, { [classes.mobile]: isMobile }),
            noWrap: true,
            variant: isMobile ? 'p2-14' : 'p2-16',
          }}
        />
        {shouldShowMembershipButton && (
          <ListItemAction>
            <GroupMembershipButton
              redirect
              upgrade={!isMobile}
              className={classes.action}
              size={isMobile ? ButtonSize.tiny : undefined}
              groupId={group.id as string}
              bi={groupsRequestJoinAGroup({
                group_id: group.id as string,
                origin: 'new_layout_groups_sidebar_suggested_groups',
              })}
            />
          </ListItemAction>
        )}
      </ListItemButton>
    </ListItem>
  );

  function handleGroupClick() {
    router.go('group', { slug: group.slug });
    groupsSelectGroupFromList({
      groupId: group.id as string,
      origin: 'new_layout_groups_sidebar_suggested_groups',
    });
  }
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
