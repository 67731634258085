import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  PrivacyStatus,
  Type as AccessRestrictionType,
} from '@wix/ambassador-social-groups-v2-group/types';

import {
  PriceFill as PriceFillIcon,
  PublicFill as PublicFillIcon,
  LockFill as LockFillIcon,
  Price as PriceIcon,
  Lock as LockIcon,
  Public as PublicIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Tooltip } from 'wui/Tooltip';

import type { IGroup } from 'store/groups/types';

interface IProps extends React.SVGAttributes<SVGElement> {
  group: IGroup;
  fillIcon?: boolean;
  size?: string | number;
  showTooltip?: boolean;
}

export function GroupPrivacyIcon(props: IProps) {
  const { group, fillIcon, showTooltip, ...rest } = props;
  const { t } = useTranslation();

  if (group.accessRestriction?.type === AccessRestrictionType.PAID_PLANS) {
    return (
      <Tooltip
        placement="top"
        content={t('groups-web.types.paid-plans.name')}
        disabled={!showTooltip}
      >
        {fillIcon ? (
          <PriceFillIcon
            {...rest}
            data-restriction={group.accessRestriction?.type}
          />
        ) : (
          <PriceIcon
            {...rest}
            data-restriction={group.accessRestriction?.type}
          />
        )}
      </Tooltip>
    );
  }

  switch (group.privacyStatus) {
    case PrivacyStatus.PUBLIC:
      return (
        <Tooltip
          placement="top"
          content={t('groups-web.types.public.name')}
          disabled={!showTooltip}
        >
          {fillIcon ? (
            <PublicFillIcon {...rest} data-privacy={group.privacyStatus} />
          ) : (
            <PublicIcon {...rest} data-privacy={group.privacyStatus} />
          )}
        </Tooltip>
      );
    case PrivacyStatus.PRIVATE:
    case PrivacyStatus.SECRET:
      return (
        <Tooltip
          placement="top"
          content={
            group.privacyStatus === PrivacyStatus.PRIVATE
              ? t('groups-web.types.private.name')
              : t('groups-web.types.secret.name')
          }
          disabled={!showTooltip}
        >
          {fillIcon ? (
            <LockFillIcon {...rest} data-privacy={group.privacyStatus} />
          ) : (
            <LockIcon {...rest} data-privacy={group.privacyStatus} />
          )}
        </Tooltip>
      );
    case PrivacyStatus.UNKNOWN:
    default:
      return null;
  }
}

GroupPrivacyIcon.displayName = 'GroupPrivacyIcon';
