import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { followMember } from '@wix/bi-logger-groups/v2';
import type { IGroupMember, IMember } from 'api/members/types';

import { useController } from 'common/context/controller';
import { selectCurrentUser, selectFollowStatus } from 'store/selectors';

import { Button } from 'wui/Button';
import {
  OverridableComponent,
  OverridableComponentProps,
} from 'wui/OverridableComponent';

import { PrivateProfileHint } from '../PrivateProfileHint';

interface IProps extends React.ComponentProps<typeof Button> {
  // used for BI
  groupId?: string;
  profile: IMember | IGroupMember;
}

export const FollowButton = React.forwardRef(
  // eslint-disable-next-line react/display-name
  (props: OverridableComponentProps<IProps, typeof Button>, ref) => {
    const { as: Component = Button, profile, groupId, ...rest } = props;

    const bi = useBi();
    const { t } = useTranslation();
    const { members$, application$ } = useController();
    const user = useSelector(selectCurrentUser);
    const status = useSelector(selectFollowStatus(profile.memberId as string));

    const isCurrentUser = user.siteMemberId === profile.memberId;
    const isFollowing = user.following.includes(profile.memberId as string);

    if (isCurrentUser) {
      return (
        <Component
          outlined
          ref={ref}
          onClick={handleCurrentUserClick}
          disabled={!application$.hasSocialPages}
          {...rest}
        >
          {t('groups-web.my-profile')}
        </Component>
      );
    }

    return (
      <PrivateProfileHint disabled={!profile.isPrivate}>
        <Component
          ref={ref}
          onClick={handleClick}
          outlined={isFollowing}
          loading={status.loading}
          disabled={status.loading || !profile.memberId}
          {...rest}
        >
          {isFollowing ? t('groups-web.following') : t('groups-web.follow')}
        </Component>
      </PrivateProfileHint>
    );

    function handleCurrentUserClick() {
      members$.navigateToMember?.({
        memberSlug: user.slug,
        memberId: user.siteMemberId,
      });
    }

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();

      if (!user.loggedIn) {
        application$.promptLogin();
        return;
      }

      isFollowing
        ? application$.unfollow(profile.memberId as string)
        : application$.follow(profile.memberId as string);

      bi.report(
        followMember({
          group_id: groupId,
          site_member_id: profile.memberId,
          origin: 'discussion_tab_link_clk',
          action: isFollowing ? 'unfollow' : 'follow',
        }),
      );
    }
  },
) as OverridableComponent<IProps, typeof Button>;

FollowButton.displayName = 'FollowButton';
