import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { IconButton } from 'wui/IconButton';
import { Lock as LockIcon } from '@wix/wix-ui-icons-common/on-stage';

import { PricingPlanDetails } from 'common/components/FeedItem/FeedItemPreview/PricingPlanDetails';

interface IProps {
  item: IFeedItem;
  onSubscribe(): void;
}

export function FeedItemSearchPreview({ item, onSubscribe }: IProps) {
  const { t } = useTranslation();

  const plans = item.accessRestriction?.paidPlansOptions?.plans || [];

  return (
    <Box gap="SP2" verticalAlign="middle">
      <Box
        style={{
          padding: 10,
          border: '1px solid ',
          borderRadius: '50%',
          width: '48px',
          height: '48px',
        }}
      >
        <IconButton
          icon={<LockIcon width={24} height={24} />}
          onClick={onSubscribe}
        />
      </Box>
      <Box direction="vertical">
        <Typography variant="h2-16">
          {t('groups-web.discussion.feed.paid-post.preview.title')}
        </Typography>
        <PricingPlanDetails plan={plans[0]} secondary />
      </Box>
    </Box>
  );
}

FeedItemSearchPreview.displayName = 'FeedItemSearchPreview';
