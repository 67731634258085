import React from 'react';

import { Box, IBoxProps } from 'wui/Box';
import { GroupListItemSkeleton } from '../GroupListItem';
import { List } from 'wui/List';
import { Divider } from 'wui/Divider';

export function GroupsResultsListSkeleton(props: IBoxProps) {
  return (
    <Box direction="vertical" {...props}>
      <List disablePadding separator={<Divider bw inset />}>
        <GroupListItemSkeleton />
        <GroupListItemSkeleton />
        <GroupListItemSkeleton />
        <GroupListItemSkeleton />
        <GroupListItemSkeleton />
      </List>
    </Box>
  );
}

GroupsResultsListSkeleton.displayName = 'GroupsResultsListSkeleton';
