import React from 'react';

import {
  FeedItemActivityType,
  IDraftJSActivity,
  IUsersJoinedActivity,
} from 'api/feed/types';

import { CoverImageChanged } from './CoverImageChanged';
import { DescriptionChanged } from './DescriptionChanged';
import { EventAdded } from './EventAdded';
import { GroupPost } from './GroupPost';
import { UserJoined } from './UserJoined';
import { UsersJoined } from './UsersJoined';
import { ManyUsersJoined } from './ManyUsersJoined';

import type { IActivityDescriptionProps } from './types';

export function ActivityDescription(props: IActivityDescriptionProps) {
  const { item } = props;

  const { activity } = item;

  if (!activity) {
    return <GroupPost {...props} />;
  }

  if (
    activity.activityType === FeedItemActivityType.DRAFT_JS ||
    activity.activityType === FeedItemActivityType.RICH_CONTENT
  ) {
    const data = activity.data as IDraftJSActivity;

    switch (data.activity) {
      case 'event_added':
        return <EventAdded {...props} />;

      default:
        return null;
    }
  }

  switch (activity.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
      return <DescriptionChanged {...props} />;

    case FeedItemActivityType.GROUP_COVER_CHANGED:
      return <CoverImageChanged {...props} />;

    case FeedItemActivityType.USERS_JOINED:
      const data = activity.data as IUsersJoinedActivity;

      switch (data.userIds.length) {
        case 1:
          return <UserJoined {...props} />;

        case 2:
          return <UsersJoined {...props} />;

        default:
          return <ManyUsersJoined {...props} />;
      }

    default:
      return null;
  }
}

ActivityDescription.displayName = 'ActivityDescription';
