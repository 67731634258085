import React, { useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { EGroupsNamespace } from 'store/groups/constants';
import { useController } from 'common/context/controller';

import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';

import { Groups } from '../Groups';
import { SIDEBAR_GROUPS_DIALOG_ROOT } from './dataHooks';

export function GroupsDialog(props: React.ComponentProps<typeof InputDialog>) {
  const { t } = useTranslation();
  const { params } = useCurrentStateAndParams();

  const { groups$ } = useController();

  useEffect(() => {
    if (props.isOpen) {
      groups$.query({
        sort: params.sort,
        namespace: EGroupsNamespace.SUGGESTED,
        partition: EGroupsNamespace.SUGGESTED,
      });

      groups$.query({
        sort: params.sort,
        namespace: EGroupsNamespace.JOINED,
        partition: EGroupsNamespace.JOINED,
      });
    }
  }, [props.isOpen]);

  return (
    <InputDialog
      {...props}
      aria-labelledby="g-dialog-title"
      paperProps={{ 'data-hook': SIDEBAR_GROUPS_DIALOG_ROOT }}
    >
      <DialogTitle
        id="g-dialog-title"
        title={t('groups-web.group-list.label')}
      />
      <DialogContent>
        <Groups />
      </DialogContent>
    </InputDialog>
  );
}

GroupsDialog.displayName = 'GroupsDialog';
