import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Popover } from 'wui/Popover';
import { Typography } from 'wui/Typography';
import { usePortalContainer } from 'wui/Portal';

interface IPrivateProfileHintProps {
  disabled?: boolean;
  className?: string;
  children: React.ReactElement;
}

export function PrivateProfileHint(props: IPrivateProfileHintProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation();
  const container = usePortalContainer();

  if (props.disabled) {
    return props.children;
  }

  const content = React.cloneElement(props.children, {
    href: undefined,
    onClick: handleOpen,
  });

  return (
    <Popover
      fixed
      appendTo={container}
      placement="top-start"
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <Box padding="SP4">
          <Typography variant="p2-14">
            {t('groups-web.private-profile-hint.description')}
          </Typography>
        </Box>
      }
    >
      {content}
    </Popover>
  );

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((isOpen) => !isOpen);
  }
}

PrivateProfileHint.displayName = 'PrivateProfileHint';
