import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './DialogContainer.scss';

interface IProps extends TPAComponentProps {
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

/**
 * Used for emulation of Dialog appearance in wui/Drawer
 */
export function DialogContainer(props: IProps) {
  const { className, children, size, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <div
      role="dialog"
      data-size={size}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.small]: size === 'sm',
        [classes.medium]: size === 'md',
        [classes.large]: size === 'lg',
      })}
      {...rest}
    >
      {children}
    </div>
  );
}

DialogContainer.displayName = 'wui/DialogContainer';
DialogContainer.defaultProps = {
  size: 'sm',
};
