import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroupRequest } from 'api/group-requests/types';

import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { InputDialog } from 'wui/InputDialog';
import { DialogActions } from 'wui/DialogActions';
import { Button } from 'wui/Button';
import { TextArea } from 'wui/TextArea';

import { selectGroupRequestsStatuses } from 'store/selectors';
import { useController } from 'common/context/controller';

import { DECLINE_GROUP_REQUEST_DIALOG } from './dataHooks';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  request: IGroupRequest;
}

export function DeclineGroupRequestDialog(props: IProps) {
  const { request, ...rest } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { groupRequests$ } = useController();

  const statuses = useSelector(selectGroupRequestsStatuses);
  const [reason, setReason] = useState('');

  const status = statuses.decline[request?.id as string] || {};

  useEffect(() => {
    if (!status.loading && !status.error) {
      props.onClose();
    }
  }, [status.loading]);

  return (
    <InputDialog
      aria-labelledby="dg-dialog-title"
      paperProps={{ 'data-hook': DECLINE_GROUP_REQUEST_DIALOG }}
      {...rest}
    >
      <DialogTitle
        id="dg-dialog-title"
        title={t('groups-web.modal.decline-group.title')}
      />
      <DialogContent>
        <TextArea
          showCharCount
          maxLength={100}
          value={reason}
          onChange={handleReasonChange}
          label={t('groups-web.modal.decline-group.subtitle', {
            memberName: request.author?.name,
          })}
          placeholder={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
          ariaLabel={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          variant="basic"
          fullWidth={isMobile}
          onClick={handleDecline}
          loading={status.loading}
        >
          {t('groups-web.modal.decline-group.action.decline')}
        </Button>
      </DialogActions>
    </InputDialog>
  );

  function handleReasonChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setReason(event.target.value);
  }

  function handleDecline() {
    groupRequests$.decline({
      reason,
      id: request.id,
    });
  }
}

DeclineGroupRequestDialog.displayName = 'DeclineGroupRequestDialog';
