import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';
import type { IGroupMember, IMember } from 'api/members/types';
import { selectCurrentUser } from 'store/selectors';

import { TextButton } from 'wui/TextButton';

import { PrivateProfileHint } from '../PrivateProfileHint';

interface IProps extends Partial<React.ComponentProps<typeof TextButton>> {
  profile?: IMember | IGroupMember;
}

export function ProfileLink(props: IProps) {
  const { profile, children, ...rest } = props;

  const { t } = useTranslation();
  const { members$, application$ } = useController();

  const user = useSelector(selectCurrentUser);

  const isPublic = !profile?.isPrivate;
  const isCurrentUser = profile?.memberId === user.memberId;
  const showPrivateProfileHint = !isPublic && !isCurrentUser;
  const name = profile?.name || t('groups-web.member.anonymous');

  if (!application$.hasSocialPages) {
    return <span title={name}>{children || name}</span>;
  }

  return (
    <PrivateProfileHint disabled={!showPrivateProfileHint}>
      <TextButton
        title={name}
        onClick={handleClick}
        as={showPrivateProfileHint ? 'span' : 'a'}
        {...rest}
      >
        {children || name}
      </TextButton>
    </PrivateProfileHint>
  );

  function handleClick() {
    members$.navigateToMember?.({
      memberId: profile?.memberId,
      memberSlug: profile?.slug,
    });
  }
}

ProfileLink.displayName = 'ProfileLink';
