import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Role } from '@wix/ambassador-social-groups-v2-group-role/types';

import {
  Admin,
  AdminFill,
  AdminFillSmall,
  AdminSmall,
} from '@wix/wix-ui-icons-common/on-stage';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';
import { Tooltip } from 'wui/Tooltip';

interface IProps extends TPAComponentProps {
  role?: Role;
  small?: boolean;
  filled?: boolean;

  tooltip?: boolean;
}

const ADMIN_ICONS = {
  filled: {
    small: AdminFillSmall,
    large: AdminFill,
  },
  outline: {
    small: AdminSmall,
    large: Admin,
  },
};

export function RoleIcon(props: IProps) {
  const { role, filled, small, tooltip, ...rest } = props;

  const { t } = useTranslation();

  const iconType = filled ? 'filled' : 'outline';
  const iconSize = small ? 'small' : 'large';

  const Icon = ADMIN_ICONS[iconType][iconSize];

  if (role !== Role.ADMIN) {
    return null;
  }

  const element = <Icon data-role={role} {...rest} />;

  if (!tooltip) {
    return element;
  }

  return (
    <Tooltip content={t('groups-web.roles.group_admin')}>{element}</Tooltip>
  );
}

RoleIcon.displayName = 'RoleIcon';
RoleIcon.defaultProps = {
  tooltip: true,
  filled: true,
  small: true,
};
