import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { type IPublicPlan } from 'api/pricing-plans/types';

import { ITypographyProps, Typography } from 'wui/Typography';
import { Show } from 'wui/Show';

interface PricingPlanDetailsProps extends ITypographyProps {
  plan: IPublicPlan;
}

export function PricingPlanDetails({
  plan,
  ...typographyProps
}: PricingPlanDetailsProps) {
  const { t } = useTranslation();

  if (!plan || !plan.translatedDetails) {
    return (
      <Typography variant="p2-16" {...typographyProps}>
        {t('groups-web.discussion.feed.paid-post.preview.no-plan-info')}
      </Typography>
    );
  }

  const { price } = plan.translatedDetails;

  return (
    <Typography variant="p2-16" {...typographyProps}>
      {price}
      <Show if={Boolean(plan.buyerCanCancel)}>
        &nbsp;|&nbsp;
        {t('groups-web.discussion.feed.paid-post.preview.buyer-can-cancel')}
      </Show>
    </Typography>
  );
}

PricingPlanDetails.displayName = 'PricingPlanDetails';
