import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';
import { UISref } from '@wix/tpa-router/react';

import stylesParams from 'Groups/stylesParams';
import { EImageRatio } from 'settings/constants';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Box } from 'wui/Box';
import { AspectRatio } from 'wui/AspectRatio';

import { Link } from 'common/components/Link';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupGridItemAction } from './GroupGridItemAction';

import classes from './GroupGridItem.scss';

interface IGroupGridItemProps {
  group: IGroup;
}

export function GroupGridItem({ group }: IGroupGridItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const styles = useStyles();

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      className={classes.root}
    >
      <AspectRatio
        className={classes.image}
        aspectRatio={styles.get(stylesParams.imageRatio) as EImageRatio}
      >
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsLivesiteClick({
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'click_on_group',
          })}
        >
          <a tabIndex={-1}>
            <GroupImage fluid resize="cover" image={group.coverImage?.image} />
          </a>
        </UISref>
      </AspectRatio>

      <CardContent disableSideGutters className={classes.content}>
        <Box verticalAlign="middle">
          <Link
            state="group"
            params={{ slug: group.slug }}
            className={cls(classes.title, { [classes.mobile]: isMobile })}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            {group.name}
          </Link>

          <RoleIcon className={classes.adminBadge} role={group.role} />
        </Box>
        <GroupInfo wired misc groupId={group.id as string} />
      </CardContent>

      <CardActions
        paddingTop="SP1"
        disableSideGutters
        className={classes.actions}
      >
        <GroupGridItemAction group={group} fullWidth />
      </CardActions>
    </Card>
  );
}

GroupGridItem.displayName = 'GroupGridItem';
