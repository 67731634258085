import React from 'react';
import cls from 'classnames';

import { type IBadge } from 'api/badges';

import { Show } from '../Show';
import { Box, type IBoxProps } from '../Box';
import { Typography } from '../Typography';

import classes from './MemberBadge.scss';

export interface IMemberBadgeProps extends IBoxProps {
  badge: IBadge;
}

export function MemberBadge(props: IMemberBadgeProps) {
  const { badge, className, ...rest } = props;

  return (
    <Box
      {...rest}
      gap="SP1"
      verticalAlign="middle"
      direction="horizontal"
      className={cls(classes.root, className)}
      style={{
        backgroundColor: badge.backgroundColor as string,
        ...rest.style,
      }}
    >
      <Show if={!!badge.icon}>
        <img
          data-hook="member-badge-icon"
          className={classes.icon}
          src={badge.icon as string}
          alt={badge.description as string}
        />
      </Show>

      <Typography
        noWrap
        variant="p2-12"
        data-hook="member-badge-title"
        title={badge.description as string}
        style={{ color: badge.textColor as string }}
      >
        {badge.title}
      </Typography>
    </Box>
  );
}

MemberBadge.displayName = 'wui/MemberBadge';
