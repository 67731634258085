import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBi } from '@wix/yoshi-flow-editor';
import { groupsSearchSearchResultSrc111Evid523 } from '@wix/bi-logger-groups-data/v2';

import {
  selectFeedSearchItems,
  selectFeedSearchMetadata,
  selectFeedResult,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { EmptyState } from 'wui/EmptyState';
import { ErrorState } from 'wui/ErrorState';
import { List } from 'wui/List';
import { Divider } from 'wui/Divider';

import { FeedResultsList } from './FeedResultsList';
import { FeedItemSearchResultSkeleton } from './FeedItemSearchResult';

import { FeedSearchConfig } from './types';

interface IProps {
  query?: string;
  container: HTMLElement | null;

  onReset(): void;

  origin: string;

  // undefined for central feed, string for group feed
  groupId?: string;

  searchConfig: FeedSearchConfig;
}

export function FeedSearchResults(props: IProps) {
  const { groupId, query, container, onReset, origin, searchConfig } = props;

  const bi = useBi();

  const { isError, isLoading, isLoadingMore } = useSelector(
    selectFeedSearchMetadata,
  );
  const items = useSelector(selectFeedSearchItems);
  const result = useSelector(selectFeedResult);

  useEffect(() => {
    if (query) {
      searchConfig.onSearch(query);
    }
  }, [query, groupId]);

  useEffect(() => {
    if (isError) {
      bi.report(
        groupsSearchSearchResultSrc111Evid523({
          origin,
          groupId,
          inputText: query,
          responseStatus: 'error',
          tabName: 'posts',
        }),
      );
    }
  }, [isError]);

  if (!query) {
    return (
      <EmptyState
        variant="section"
        title={searchConfig.emptyTitle}
        subtitle={searchConfig.emptySubtitle}
      />
    );
  }

  if (isLoading) {
    return (
      <Box direction="vertical">
        <List disablePadding separator={<Divider bw inset />}>
          <FeedItemSearchResultSkeleton />
          <FeedItemSearchResultSkeleton />
          <FeedItemSearchResultSkeleton />
        </List>
      </Box>
    );
  }

  if (isError) {
    return (
      <ErrorState
        bw
        variant="section"
        onRetry={handleRetry}
        buttonProps={{ variant: 'basic', outlined: true }}
        title={searchConfig.errorTitle}
        subtitle={searchConfig.errorSubtitle}
        actionLabel={searchConfig.actionLabel}
      />
    );
  }

  return (
    <FeedResultsList
      query={query}
      groupId={groupId}
      origin={origin}
      onReset={onReset}
      container={container}
      onLoadMore={handleLoadMore}
    />
  );

  function handleRetry() {
    searchConfig.onSearch(query!);
  }

  function handleLoadMore() {
    if (isLoadingMore) {
      return;
    }

    searchConfig.onSearchMore(
      query!,
      groupId ? items.length : Number(result.nextCursor),
    );
  }
}

FeedSearchResults.displayName = 'FeedSearchResults';
