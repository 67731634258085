import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';
import { selectGroupSlugById } from 'store/selectors';
import { useRelativeTimeFormatter } from 'common/hooks';

import { Box } from 'wui/Box';
import { Stack } from 'wui/Stack';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { Avatar } from 'wui/Avatar';
import { CardHeader, type ICardHeaderProps } from 'wui/CardHeader';

import { Link } from '../../Link';
import { RoleIcon } from '../../RoleIcon';
import { ProfileLink } from '../../ProfileLink';
import { MemberBadges } from '../../MemberBadges';

import { getAriaId } from '../a11y';
import { ActivityDescription } from './ActivityDescription';

import classes from '../FeedItem.scss';

interface IProps {
  item: IFeedItem;

  promote?: boolean;
}

export function FeedItemHeader(props: IProps & Partial<ICardHeaderProps>) {
  const { item, promote, ...rest } = props;

  const { isMobile } = useEnvironment();
  const rtf = useRelativeTimeFormatter();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <CardHeader
      avatar={
        <ProfileLink
          tabIndex={-1}
          profile={item.createdBy}
          data-hook="author-avatar-link"
        >
          <Avatar
            lettersLimit={2}
            name={item.createdBy.name}
            src={item.createdBy.imageUrl}
            data-hook="author-avatar"
            size={isMobile ? 'medium' : 'large'}
          />
        </ProfileLink>
      }
      title={
        <Box
          as="div"
          verticalAlign="middle"
          id={getAriaId(feedItemId, 'author')}
        >
          <Typography
            as="div"
            className={classes.author}
            variant={isMobile ? 'p2-14' : 'p2-16'}
          >
            <ProfileLink
              profile={item.createdBy}
              data-hook="author-name-link"
            />
            <RoleIcon
              role={item.createdBy?.role}
              data-hook="author-role-icon"
            />
          </Typography>
        </Box>
      }
      subtitle={
        <Typography
          as="div"
          secondary
          className={classes.subtitle}
          variant={isMobile ? 'p2-12' : 'p2-14'}
        >
          <Box gap="SP2" direction="vertical" className={classes.timestamp}>
            <Stack
              as="div"
              flex={false}
              separator={<span> · </span>}
              id={getAriaId(feedItemId, 'meta')}
            >
              <Link
                state="group.discussion.post"
                params={{ slug, feedItemId }}
                className={classes.timestamp}
                data-hook="created-date"
              >
                {rtf(item.createdAt as Date)}
              </Link>
              <Show if={!!item.activity || promote}>
                <ActivityDescription full={promote} item={item} />
              </Show>
            </Stack>
            <MemberBadges member={item.createdBy} />
          </Box>
        </Typography>
      }
      {...rest}
    />
  );
}

FeedItemHeader.displayName = 'FeedItemHeader';
