import React from 'react';
import cls from 'classnames';

import { TextArea as TextAreaTPA, TextAreaProps } from 'wix-ui-tpa/cssVars';

import classes from './TextArea.scss';

interface ITextFieldProps extends TextAreaProps {}

export function TextArea(props: ITextFieldProps) {
  const { className, ...rest } = props;

  return (
    <TextAreaTPA
      newErrorMessage
      className={cls(classes.root, className)}
      {...rest}
    />
  );
}

TextArea.displayName = 'wui/TextArea';
