import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsSearchSearchResultSrc111Evid523 } from '@wix/bi-logger-groups-data/v2';

import { Box } from 'wui/Box';
import { List } from 'wui/List';
import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { Divider } from 'wui/Divider';
import { EmptyState } from 'wui/EmptyState';

import { EGroupPartition, IGroup } from 'api/groups/types';

import { useController } from 'common/context/controller';
import { selectGroups } from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { GroupListItem } from '../GroupListItem';
import { GroupsResultsListSkeleton } from './GroupsResultsList.skeleton';

interface IProps {
  query: string;
  container: HTMLElement | null;
  origin: string;

  onReset(): void;
}

export function GroupsResultsList(props: IProps) {
  const { query, container, onReset, origin } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { groups$ } = useController();

  const { groups, meta, status } = useSelector(
    selectGroups(EGroupsNamespace.SEARCH),
  );

  const hasMore = meta.total! > groups.length;

  useEffect(() => {
    if (meta.total) {
      bi.report(
        groupsSearchSearchResultSrc111Evid523({
          origin,
          responseStatus: 'success',
          outputJson: JSON.stringify({ groups: meta.total }),
          tabName: 'groups',
        }),
      );
    } else {
      bi.report(
        groupsSearchSearchResultSrc111Evid523({
          origin,
          responseStatus: 'no_results',
          tabName: 'groups',
        }),
      );
    }
  }, [meta.total]);

  if (!groups.length) {
    return (
      <EmptyState
        variant="section"
        title={t('groups-web.groups-search.groups-empty-state.title')}
        subtitle={t('groups-web.groups-search.groups-empty-state.subtitle')}
        action={
          <Button outlined onClick={onReset}>
            {t('groups-web.groups-search.groups-empty-state.reset-button')}
          </Button>
        }
      />
    );
  }

  return (
    <Stack gap="SP2" direction="vertical">
      <Box padding="SP0 SP6">
        <Typography secondary>
          {t('groups-web.groups-search.groups.results-total', {
            count: meta.total!,
          })}
        </Typography>
      </Box>

      <InfiniteScroll
        threshold={400}
        useWindow={false}
        hasMore={hasMore}
        initialLoad={false}
        loadMore={handleLoadMore}
        getScrollParent={() => container}
        loader={
          <Show if={!!status.fetchMore?.loading}>
            <Divider bw inset />
            <GroupsResultsListSkeleton />
          </Show>
        }
      >
        <List disablePadding separator={<Divider bw inset />}>
          {groups.map((group: IGroup) => (
            <GroupListItem
              key={group.id}
              group={group}
              data-hook="group-list-item"
              origin={origin}
              query={query}
            />
          ))}
        </List>
      </InfiniteScroll>
    </Stack>
  );

  function handleLoadMore() {
    if (!status.fetchMore?.loading) {
      groups$.query({
        namespace: EGroupsNamespace.SEARCH,
        partition: EGroupPartition.ALL,
        title: query,
        offset: groups.length,
        limit: 15,
      });
    }
  }
}

GroupsResultsList.displayName = 'GroupsResultsList';
